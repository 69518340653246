@import "../../global.scss";

.navbar {
  position: fixed;
  background-color: $ivory;
  background-color: transparent;
  height: 70px;
  z-index: 4;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.4s;

  font-size: 0.9rem;

 

  .name {
    font-size: 3rem;
    font-weight: 800;
    color: $mainColor;
    text-shadow: -2px 0px 0px black;
  }

  .hamburger {
    position: fixed;
    width: 32px;
    height: 25px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 4;
    span {
      color: black;
      background-color: black;
      width: 100%;
      height: 3px;
      transform-origin: right;
      transition: all 1s ease;
    }
  }

  &.active {
    color: rgb(255, 0, 0);

    .hamburger {
      overflow: hidden;
      span {
        &:first-child {
          background-color: rgb(255, 0, 0);
          transform: rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: rgb(255, 0, 0);
          transform: rotate(45deg);
        }
      }
    }
  }

  &.active {
    background-color: $thirdColor;
  }
}
