@import "../../global.scss";

.portfolio {
    width: 100vw;
    height: calc(100vh - 70px);
    top: 70px;
    background-color: #0c0c0c;

    @include mobile {
        top: 0px;
    }

    .wrapper {
        position: relative;

        display: flex;
        width: 100%;
        height: 100%;

        @include mobile {
            flex-direction: column-reverse;
        }

        .portfolio-section {
            height: 90%;

            flex: 2;
            display: flex;
            flex-direction: column;

            @include mobile {
                height: 100%;
            margin-bottom: 30px;

                
            }

            h1 {
                text-align: center;
                color: rgb(238, 238, 238);
                font-size: 6rem;
                font-weight: 600;
                margin: 10%;
                margin-bottom: 15px;
                overflow:visible;

                @include mobileM{
                   
                    display: none;

             

                           
                       }


                @include mobile {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-bottom: -30px;
                    font-size: 3rem;
                    display: none;
                }
            }

            .portfolio-main {
                position: relative;
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 20px;

                @include mobileM{
                   margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    align-content: center;

             

                           
                       }

                @include mobile {
                    align-items: center;
                    flex-direction: column-reverse;
                    gap: 5px;
                    height: 100%;
                }

                .portfolio-item {

                    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.501);
                    border-radius: 5px;
                    height: 380px;
                    width: 500px;
                    background-color: white;
                    border: 3px solid white;

                    @include mobile {
                        padding-top: 10px;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 20px;

                     }

                    @include mobile {
                        height: 240px;
                        width: 350px;
                    }

                    a {

                      
                        overflow: hidden;


                        img {
                            @include mobile {
                            overflow: hidden;

                                height: auto;
                                width: 300px;
                            }
                        }
                    }
                    .item-desc {
                        color: rgb(34, 34, 34);
                        padding-top: 2px;
                        text-align: center;
                        font-weight: 800;
                        font-size: 1.7rem;
                        overflow: hidden;

                    


                        .tec {
                            overflow: hidden;
                            justify-content: center;
                            padding-top: 5px;
                            display: flex;
                            flex-direction: row;
                            gap: 5px;

                            @include mobile {
                                display: none;
                                }

                            p {
                                padding: 3px;
                                border-radius: 10px;
                                background-color: rgb(35, 195, 104);
                                color: rgb(46, 46, 46);
                                font-size: 0.9rem;

                                @include mobile {
                                font-size: 0.5rem;
                                  
                                    
                                }
                            }
                        }
                    }
                }
            }
        }

        .portfolio-animation {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile {
                display: none;
            }

            @include mobileM{
                   
                display: none;

         

                       
                   }

            .animation-wrapper {
                overflow: hidden;
                height: 80%;
            }
        }
    }
}
