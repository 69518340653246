@import '../../global.scss';

.contact{
    background-color: $ivory;
    width: 100vw;
    height: calc(100vh - 70px);
    top: 70px;



    .wrapper{
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        
        @include mobile{
                   
         flex-direction: column;
                
            }

        
        .animation{
            position: relative;
            overflow: hidden;
            flex: 3;

              
        @include mobile{
                   
            padding-bottom: 50px;
                   
               }
            
        }

        .form{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 4;
            justify-content: center;
            align-items: center;
            text-align: center;

            h1{
                overflow: hidden;
                margin-bottom: 30px;
                font-size: 4rem;
                color: rgb(36, 36, 36);

                @include mobile{
                   
                    font-size: 3rem;
             

                           
                       }

            }    

            form{
                height: 60%;
                width: 60%;
                @include mobile{
                   
                    flex-direction: column;
                height: 90%;
                width: 90%;

                           
                       }
            }


            input[type="text"]{
                font-size: 1.2rem;
                
                width: 89%;
                height: auto;
                display: inline-block;
                border: none;
                padding: 5px;
                height: 30px;
                outline: none;
                border: 2px solid $mainColor;
                margin-bottom: 20px;
                

                  
        @include mobile{
                   
            margin-bottom: 10px;
                
            }

            }

          

            textarea{
                font-size: 1.2rem;

                resize: none;
                width: 90%;
                height: 50%;
                border: 2px solid $mainColor;
                margin-bottom: 10px;

                &:focus{
                    border: 2px solid $mainColor;
                    outline: none;
                }

            }


            input[type="submit"]{
                color: white;
                font-weight: 700;
                font-size: 1.5rem;
                background-color: $mainColor;
                width: 91%;
                height: auto;
                display: inline-block;
                padding: 10px;
                border: none;

            }
            
        }

        .footer{
            bottom: 0px;
            position: absolute;
            width: 100%;
            height: 100px;
            background-color: rgb(26, 26, 26);
            display: flex;
               
            justify-content: center;
            align-items: center;

            .leftContainer{
                color: white;
             
            }


        }

    }

}