.app{
    height: 100vh;
    background-color: #949494;

    .sections{
        width: 100%;
        height:100vh;
        background-color: lightsalmon;
        position: relative;
        // top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //for firefox
        &::-webkit-scrollbar{
            display: none;
        }

        > *{
            width: 100vw;
            height:100vh;
            scroll-snap-align: start;
        }
    }

    
}